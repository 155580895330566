const generateRandomState = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

const state = generateRandomState();

const redirectToFigmaOAuth = () => {
    const clientIdFigma = process.env.NEXT_PUBLIC_FIGMA_CLIENT_ID;
    const redirectUriFigma = `${process.env.NEXT_PUBLIC_BASE_URL_EXTERNAL}/figmaCallback`;
    const scopeFigma = 'file_read';

    const url = `https://www.figma.com/oauth?client_id=${clientIdFigma}&redirect_uri=${redirectUriFigma}&scope=${scopeFigma}&state=${state}&response_type=code`;

    window.location.replace(url);
};

const redirectToAtlassianOAuth = () => {
    const clientIdAtlassian = process.env.NEXT_PUBLIC_ATLASSIAN_CLIENT_ID;
    const redirectUriAtlassian = `${process.env.NEXT_PUBLIC_BASE_URL_EXTERNAL}/atlassianCallback`;
    const scopeAtlassian = 'report%3Apersonal-data%20read%3Ajira-work%20read%3Ajira-user%20offline_access';

    const url = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=${clientIdAtlassian}&scope=${scopeAtlassian}&redirect_uri=${redirectUriAtlassian}&state=${state}&response_type=code&prompt=consent`;
    console.log(url);
    window.location.replace(url);
};

const redirectToSlackOAuth = () => {
    const clientIdSlack = process.env.NEXT_PUBLIC_SLACK_CLIENT_ID;
    const redirectUriSlack = `${process.env.NEXT_PUBLIC_BASE_URL_EXTERNAL}/slackCallback`;
    const scopeSlack = [
        'channels:history', 'channels:read', 'files:read', 'groups:history', 'groups:read', 'im:read', 'links:read', 'mpim:read', 'remote_files:read', 'team:read', 'usergroups:read', 'users:read', 'users:read.email', 'identify', 'im:history', 'mpim:history', 'users.profile:read'
    ].join('%20');
    const url = `https://slack.com/oauth/v2/authorize?client_id=${clientIdSlack}&user_scope=${scopeSlack}&redirect_uri=${redirectUriSlack}`;
    window.location.replace(url);
};

const redirectToGmailOAuth = () => {
    const clientIdGmail = process.env.NEXT_PUBLIC_GMAIL_CLIENT_ID;
    const redirectUriGmail = `${process.env.NEXT_PUBLIC_BASE_URL_EXTERNAL}/gmailCallback`;
    const scopeGmail = [
        'https://www.googleapis.com/auth/gmail.readonly',
        'https://www.googleapis.com/auth/userinfo.email',
        'https://www.googleapis.com/auth/userinfo.profile'
    ];
    const scopeUrl = scopeGmail.join(' ');
    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientIdGmail}&response_type=code&redirect_uri=${encodeURIComponent(redirectUriGmail)}&scope=${encodeURIComponent(scopeUrl)}&access_type=offline&prompt=consent`;
    console.log(url);
    window.location.replace(url);
};

const redirectToNotionOAuth = () => {
    const clientIdNotion = process.env.NEXT_PUBLIC_NOTION_CLIENT_ID;
    const redirectUriNotion = `${process.env.NEXT_PUBLIC_BASE_URL_EXTERNAL}/notionCallback`;

    const url = `https://api.notion.com/v1/oauth/authorize?client_id=${clientIdNotion}&response_type=code&owner=user&redirect_uri=${encodeURIComponent(redirectUriNotion)}`;

    window.location.replace(url);
};

export { redirectToFigmaOAuth, redirectToAtlassianOAuth, redirectToSlackOAuth, redirectToGmailOAuth, redirectToNotionOAuth };