import React, { useEffect, useState } from "react";
const axios = require("axios");

import {
  SimpleGrid,
  Modal,
  Image,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import ServiceCard from "./ServiceCard";
import { useUser } from "../contexts/user";
import {
  FIGMA_USERS_TABLE,
  ATLASSIAN_USERS_TABLE,
  SLACK_USERS_TABLE,
  GMAIL_USERS_TABLE,
  NOTION_USERS_TABLE
} from "../config";
import {
  redirectToFigmaOAuth,
  redirectToAtlassianOAuth,
  redirectToSlackOAuth,
  redirectToGmailOAuth,
  redirectToNotionOAuth
} from "../utils/createAuthURL";
import { supabaseClient } from "../utils/client";

const services = [
  {
    name: 'Figma',
    table: FIGMA_USERS_TABLE,
    logo: '/figma-icon.svg',
    connectUser: redirectToFigmaOAuth
  },
  {
    name: 'Jira',
    table: ATLASSIAN_USERS_TABLE,
    logo: '/atlassian_jira-icon.svg',
    connectUser: redirectToAtlassianOAuth
  },
  {
    name: 'Slack',
    table: SLACK_USERS_TABLE,
    logo: '/slack-icon.svg',
    connectUser: redirectToSlackOAuth
  },
  {
    name: 'Gmail',
    table: GMAIL_USERS_TABLE,
    logo: '/gmail-icon.svg',
    connectUser: redirectToGmailOAuth
  },
  {
    name: 'Notion',
    table: NOTION_USERS_TABLE,
    logo: '/notion-icon.svg',
    connectUser: redirectToNotionOAuth
  }
];

const ServiceOverview = () => {
  const { user, authInfo, setAuthInfo } = useUser()
  const [teamId, setTeamId] = useState(authInfo[FIGMA_USERS_TABLE] || '');
  const [showTeamIdModal, setShowTeamIdModal] = useState(false);
  const [showRemoveAuthAlert, setShowRemoveAuthAlert] = useState(false);
  const [tableToDelete, setTableToDelete] = useState();

  const cancelRemoveAuthRef = React.useRef()

  useEffect(() => {
    const fetchAuthStatus = async () => {
      if(!user.id) {return}
      const authStatus = {};
      const serviceTables = [
        FIGMA_USERS_TABLE,
        ATLASSIAN_USERS_TABLE,
        SLACK_USERS_TABLE,
        GMAIL_USERS_TABLE,
        NOTION_USERS_TABLE
      ];
      for (const tableName of serviceTables) {
        const response = await supabaseClient
          .from(tableName)
          .select("access_token")
          .eq("supabase_user_id", user.id);
        const isConnected = response.data && response.data.length > 0 && !!response.data[0].access_token;
        authStatus[tableName] = {
          isConnected,
        };
      }
      setAuthInfo((prevState) => ({
        ...prevState,
        ...authStatus,
      }));
    };
    fetchAuthStatus();
  }, [authInfo.loading]);

  useEffect(() => {
    const fetchTeamIdFromSupabase = async () => {
      if (authInfo[FIGMA_USERS_TABLE]?.isConnected) {
        try {
          const { data, error } = await supabaseClient
            .from(FIGMA_USERS_TABLE)
            .select("figma_team_id")
            .eq("supabase_user_id", user.id)
            .single();

          if (error) {
            throw error;
          }

          setTeamId(data?.figma_team_id || null);

          setShowTeamIdModal(!data?.figma_team_id);
        } catch (error) {
          console.error('Error fetching team ID from Supabase:', error.message);
        }
      } else {
        setTeamId(null);
        setShowTeamIdModal(false);
      }
    };

    fetchTeamIdFromSupabase();
  }, [authInfo[FIGMA_USERS_TABLE]?.isConnected, user.id]);


  const handleTeamIdModalClose = () => {
    setShowTeamIdModal(false);
  };
  const deleteAccessToken = async (tableName) => {

    const updatePayload = { 'access_token': null, 'updated_at': new Date() };
    if (authInfo[tableName]?.refresh_token) {
      updatePayload['refresh_token'] = null;
    }
    const { error: updateError } = await supabaseClient
      .from(tableName)
      .update(updatePayload)
      .eq("supabase_user_id", user.id);
  }
  const handleTeamIdSave = async () => {
    try {
      await axios.post('api/save-figma-team-id', { figma_team_id: teamId, supabase_user_id: user.id });
      console.log(`Team ID updated successfully`);
      setShowTeamIdModal(false);
    } catch (error) {
      console.log('Error updating team ID:', error.message);
    }
  };
  const handleRemoveAuth = async (tableName, name) => {
    setTableToDelete(tableName)
    setShowRemoveAuthAlert(true)
  };
  const onConfirmRemoveAuth = async () => {
    await deleteAccessToken(tableToDelete);
    setAuthInfo((prevState) => ({
      ...prevState,
      [tableToDelete]: { isConnected: false }
    }));
    setShowRemoveAuthAlert(false);
  };
  const initialRef = React.useRef(null)

  const servicesEnhanced = services.map(service => {
    let enhancedService = {
      ...service,
      connectUser: service.connectUser,
      disconnectUser: () => handleRemoveAuth(service.table),
    };

    if (service.name === 'Figma') {
      enhancedService = {
        ...enhancedService,
        showSecondaryButton: true,
        secondaryButtonText: `Team ID`,
        onSecondaryButtonClick: () => setShowTeamIdModal(true),
      };
    }

    return enhancedService;
  });

  services.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <SimpleGrid columns={1} spacing={2} p='10px'>
        {servicesEnhanced.map((service) => (
          <ServiceCard
            key={service.name}
            isConnected={authInfo[service.table]?.isConnected}
            {...service}
          />
        ))}
      </SimpleGrid>
      <Modal isOpen={showTeamIdModal} onClose={handleTeamIdModalClose} initialFocusRef={initialRef} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Figma Team ID needed</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            Here you find the Team ID:
            <Image
              src="/figma-team-id.jpg"
              mb={10}
              mt={2}
            />
            <FormControl>
              <Input
                ref={initialRef}
                placeholder='Enter Figma Team ID here'
                onChange={(event) => setTeamId(event.target.value)}
                value={teamId || ""}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={handleTeamIdSave} disabled={!teamId}>
              Save
            </Button>
            <Button colorScheme='red' onClick={handleTeamIdModalClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={showRemoveAuthAlert}
        leastDestructiveRef={cancelRemoveAuthRef}
        onClose={() => setShowRemoveAuthAlert(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Disconnect service
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to disconnect this service?
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRemoveAuthRef} onClick={() => setShowRemoveAuthAlert(false)}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={onConfirmRemoveAuth} ml={3}>
                Disconnect
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};

export {services};
export default ServiceOverview;