import React, { useState, useRef } from "react";
import {
  Flex,
  Box,
  Center,
  Image,
  Button,
  useColorModeValue,
  CircularProgress,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { useUser } from "../contexts/user";
import Signup from "../components/Signup";

const ServiceCard = ({
  logo,
  name,
  connectUser,
  disconnectUser,
  isConnected,
  showSecondaryButton = false,
  secondaryButtonText,
  onSecondaryButtonClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useUser();
  const signupRef = useRef();

  const handleAddClick = () => {
    if (user.id) {
      connectUser();
    } else {
        signupRef.current.openModal('Sign up', 'solid', 'to connect your service')
    }
  };

  return (
    <Flex
      h={"full"}
      bg={"gray.800"}
      rounded={"md"}
      overflow={"hidden"}
      alignItems={"center"}
      m={{ base: "", md: "0 25px" }}
      p="0px 60px 0px 10px"
    >
      <Center m="2">
        <Box>
          <Image
            p={2}
            h={"50px"}
            w={"full"}
            src={logo}
            objectFit={"contain"}
            minW={"80px"}
          />
          <Center fontSize={"md"} fontWeight={500} fontFamily={"body"} pb={2}>
            {name}
          </Center>
        </Box>
      </Center>
      <Box p={"6px 6px 6px 0px"}>
        <Flex flexDir={"column"}>
          <Button
            onClick={isConnected ? disconnectUser : handleAddClick}
            w={"full"}
            m={2}
            minWidth={"110px"}
            bg={useColorModeValue("#151f21", "gray.900")}
            color={"gray.300"}
            rounded={"md"}
            leftIcon={!isConnected ? <FaPlus /> : null}
            _hover={{
              boxShadow: "md",
              bg: "gray.700",
              color: "white"
            }}
            disabled={isLoading}
          >
            {isLoading && !isConnected ? (
              <CircularProgress isIndeterminate size="24px" color="black" />
            ) : (
              isConnected ? "Remove" : "Add"
            )}
          </Button>
          {showSecondaryButton && (
            <Button
              w={"full"}
              m={2}
              bg={useColorModeValue("#151f21", "gray.900")}
              color={"gray.300"}
              rounded={"md"}
              onClick={onSecondaryButtonClick}
              _hover={{
                boxShadow: "md",
                bg: "gray.700",
                color: "white"
              }}
            >
              {secondaryButtonText}
            </Button>
          )}
        </Flex>
      </Box>
      <Signup ref={signupRef} buttonText="Sign Up" buttonVariant="solid" />
    </Flex>
  );
};

export default ServiceCard;
